.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: #f5593d !important;
  border-color: #f5593d !important;
  &:focus {
    outline: none !important;
  }
  &:active {
    outline: none !important;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control {
  margin-left: 3rem !important;
  padding-bottom: 0.5rem;

}
.custom-control-inline {
  display:inherit !important;
}
.required-option {
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid #dddddd;
  // margin-bottom: 0.8rem;
  &__italics {
    font-style: italic;
    color: grey;
  }
}
.req-option {
  padding: 0 1rem .5rem 1rem;

}
.additional-pref {
  margin-top: 1rem;
}
.food-modal {
  font-weight: 900 !important;
}
.addon {
  // border-bottom: 1px solid #dddddd;
  // border-top: 1px solid #dddddd;
  padding-bottom: .5rem;
}

.modal-header + .adjacent > .addon {
  border-top: none !important;
}
.tooltip-wrapper {
  display: inline-block !important; /* display: block works as well */
}

.tooltip-wrapper .btn[disabled] {
  /* don't let button block mouse events from reaching wrapper */
  pointer-events: none !important;
}

.tooltip-wrapper.disabled {
  /* OPTIONAL pointer-events setting above blocks cursor setting, so set it here */
  cursor: not-allowed !important;
}
