.cart-item {
  border: 1px black !important;
  &:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15) !important;
    transform: none !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    -moz-transform: none !important;
    cursor: default !important;
    background-color: #ffffff !important;
  }
}
.cart-header {
  background-color: rgba(255, 255, 255, 0.03) !important;
}
.cart-item-quantity {
  display: inline-block;
  background: rgb(246, 246, 248);
  margin-right: 8px;
  padding: 0 8px;
  text-align: center;
  font-weight: 900;
}

.cart-item-body {
  padding-top: 10px !important;
}

.bm-cross-button1 {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 8px;
}

.span-class {
  position: absolute;
  top: 6px;
  right: 14px;
}

.bm-cross1 {
  position: absolute;
  width: 3px;
  height: 14px;
  transform: rotate(45deg);
  background: #bdc3c7;
}

.bm-cross2 {
  position: absolute;
  width: 3px;
  height: 14px;
  transform: rotate(-45deg);
  background: #bdc3c7;
}

.button-class1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: none;
  font-size: 0px;
  background: transparent;
  cursor: pointer;
}
