.Menu-navbar {
  font-size: 15px !important;
}
a {
  color: #f5593d !important;
}

h3 {
  color: rgb(25, 25, 25) !important;
  font-weight: 700 !important;
}

.sticky {
  top: 70px !important;
  z-index: 99 !important;
}

.padding0 {
  padding-top: 65px;
}

.navbar > .container {
  justify-content: space-between !important;
}
.nav-mobile-spread {
  @media screen and (max-width: 991px) {
    width: auto !important;
  }
}

.navbar-nav {
  top: 70px !important;
}
.menu-nav-sticky {
  margin: 0 !important;
  position: sticky !important;
  top: 70px !important;

  box-sizing: border-box !important;
  z-index: 5 !important;
}
