.not-open {
  display: flex !important;
  justify-content: center;
  margin-top: 2rem;
}

.not-open-parent {
  margin: 0 !important;
  position: sticky !important;
  top: 158px !important;

  box-sizing: border-box !important;
  z-index: 5 !important;
}
