.customb {
  display: block;
  color: #ffffff !important;
  background-color: #f5593d !important;
  border-color: #f5593d !important;
  bottom: 0px !important;
  margin: 0 0 50px !important;
  width: 95% !important;
  &:focus {
    outline: none !important;
  }
  &:hover {
    background-color: darken(#f5593d, 8%) !important;
    border-color:darken(#f5593d, 8%) !important;
  }
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  @media (min-width: 1400px) {
    width: 360px !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #f0f1f5;
  padding: 2.5em 0.4em 0 0;
  font-size: 1.15em;
  @media (min-width: 1400px) {
    width: 240px !important;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #f0f1f5;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.2em;
  width: 160% !important;
  @media (min-width: 1400px) {
    width: 140% !important;
    left: -105px !important;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.empty-message:focus {
  outline: none !important;
  text-align: center;
  color: black;
}
