
.nav-link {
    margin:10px;
}
.nav-link.active {
    border-top: solid !important;
}
/* .nav {
    text-align: center;
    left: 50%;
    margin-right: -50%;
}
.nav-tabs {
    text-align: center;
    left: 50%;
    margin-right: -50%;
} */

/* .orderMethod {
    display: block;
    margin-left: auto;
    margin-right: auto;
} */