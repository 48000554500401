.pay-button-container {
    width:160px;
    margin-top:30px;


}
.tooltip-test {
    
  font-family: 'Montserrat', "Helvetica", Arial, sans-serif;

};


